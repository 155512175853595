<template>
  <div class="main">
    <template v-if="dataList.length > 0">
      <div class="mainContent">
        <div class="left-con">
          <div
            class="con-item"
            :class="item.id == currentId ? 'active' : ''"
            v-for="(item, index) in dataList"
            :index="index"
            :key="item.id"
            @click="handleOpen(item)"
          >
            {{ item.name }}
          </div>
          <!-- <el-menu
            background-color="#ffffff"
            text-color="#333333"
            active-text-color="#009CFF"
            default-active="0"
            class="el-menu-vertical-demo"
            @select="handleOpen"
          >
            <el-menu-item
              v-for="(item, index) in dataList"
              :index="index"
              :key="item.id"
            >
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </el-menu> -->
          <div class="button">
            <el-button
              style="width: 232px; height: 47px; color: #009cff; border: none"
              icon="el-icon-circle-plus-outline"
              plain
              @click="handleAdd()"
              >添加队列</el-button
            >
          </div>
        </div>
        <div class="rightContent">
          <div class="topContent">
            <div class="text-con">
              <div class="text1">{{ info.name }}</div>
              <img src="~@/assets/img/queue/name.png" alt="" />
              <div class="text2">{{ info.doctorName }}</div>
              <img
                style="margin-left: 40px"
                src="~@/assets/img/queue/time.png"
                alt=""
              />
              <div class="text2">创建时间：{{ info.createTime }}</div>
            </div>
            <div class="setting" @click="goToSetting">
              <i class="el-icon-setting"></i>
            </div>
          </div>
          <div style="padding-right: 24px">
            <el-form>
              <el-form-item>
                <el-input
                  style="width: 240px; margin-left: 24px"
                  placeholder="姓名查询"
                  v-model="form.patienceName"
                  class="input-with-select"
                >
                  <el-button
                    slot="append"
                    @click="getDataList2()"
                    icon="el-icon-search"
                  ></el-button>
                </el-input>
              </el-form-item>
              <el-form-item label="性别">
                <el-select
                  style="width: 240px"
                  v-model="form.sex"
                  placeholder="请选择"
                >
                  <el-option label="男" value="1"></el-option>
                  <el-option label="女" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="年龄区间">
                <el-input
                  style="width: 114px"
                  v-model="form.startAge"
                  placeholder=""
                  autocomplete="off"
                ></el-input
                >&nbsp;—
                <el-input
                  style="width: 114px"
                  v-model="form.endAge"
                  placeholder=""
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="诊断查询">
                <el-select
                  style="width: 240px"
                  filterable
                  v-model="form.mainDiagnosis"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in typeArr"
                    :label="item.name"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div style="margin-left: 24px" class="button">
              <el-button @click="getDataList2()" type="primary">搜索</el-button>
              <el-button @click="reset()" type="reset">重置</el-button>
            </div>
            <div class="fgline"></div>
            <div style="margin-left: 24px; padding-bottom: 24px" class="button">
              <el-button @click="getDataList3()" type="primary"
                >病例纳入</el-button
              >
              <el-button
                @click="deleteHandle()"
                :disabled="dataListSelections.length <= 0"
                type="primary"
                >病例排除</el-button
              >
              <!-- 			<el-button  @click="getDataList()" type="primary">导出</el-button> -->
            </div>
            <el-table
              style="margin-left: 24px; width: 99%"
              ref="multipleTable"
              :data="dataList2"
              @selection-change="selectionChangeHandle"
              tooltip-effect="dark"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column
                label="姓名"
                prop="patienceName"
              ></el-table-column>
              <el-table-column label="性别">
                <template slot-scope="scope">{{
                  scope.row.patienceSex == 1 ? "男" : "女"
                }}</template>
              </el-table-column>
              <el-table-column
                prop="patienceAge"
                label="年龄"
              ></el-table-column>
              <el-table-column
                prop="patienceLinkTel"
                label="联系方式"
              ></el-table-column>
              <el-table-column
                prop="visitInfoTime"
                label="入组日期"
              ></el-table-column>
              <el-table-column
                prop="visitInfoTime"
                label="最近访视日期"
              ></el-table-column>
              <el-table-column
                prop="payload"
                label="操作"
                show-overflow-tooltip
                align="right"
                header-align="right"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                    >详情</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              style="margin-left: 24px"
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :total="totalPage"
              layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
          </div>
        </div>
      </div>
      <!--     -->
    </template>
    <template v-else>
      <div class="zwsjArea">
        <img src="~@/assets/img/queue/zwsj.png" alt="" />
        <div class="text">本团队暂未添加队列！</div>
        <div @click="handleAdd" class="button">立即添加</div>
      </div>
    </template>
    <QueueCreate
      v-if="dialogStatus"
      @closeDialog="closeDialog"
      @refreshDataList="getDataList"
      :state.sync="dialogStatus"
    ></QueueCreate>
    <el-dialog
      title="纳入病例"
      @close="closeChooseMember"
      customClass="customWidth"
      :visible.sync="dialogTableVisible"
      width="1200px"
    >
      <el-form :inline="true" class="form-content" style="padding: 0 14px;">
        <el-form-item>
          <el-input
            style="width: 240px;"
            placeholder="姓名查询"
            v-model="form3.patienceName"
            class="input-with-select"
          >
            <el-button
              slot="append"
              @click="getDataList3()"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select
            style="width: 240px"
            v-model="form3.patienceSex"
            placeholder="请选择"
          >
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="诊断查询">
          <el-select
            style="width: 240px"
            filterable
            v-model="form3.mainDiagnosis"
            placeholder="请选择"
          >
            <el-option
              v-for="item in typeArr"
              :label="item.name"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄区间">
          <el-input
            style="width: 114px"
            v-model="form3.patienceAgeMin"
            placeholder=""
            autocomplete="off"
          ></el-input>
          <span class="divider-line"></span>
          <el-input
            style="width: 114px"
            v-model="form3.patienceAgeMax"
            placeholder=""
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="getDataList3()" type="primary">搜索</el-button>
          <el-button @click="reset3()" type="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="dataList3"
        ref="multipleTable"
        @selection-change="selectionChangeHandle2"
        style="width: 100%;padding: 0 14px;"
      >
        <el-table-column
          type="selection"
          header-align="center"
          width="50"
        ></el-table-column>
        <el-table-column label="姓名" prop="patience_name" width="200"></el-table-column>
        <el-table-column label="性别" width="120">
          <template slot-scope="scope">{{
            scope.row.patience_sex == 1 ? "男" : "女"
          }}</template>
        </el-table-column>
        <el-table-column prop="patience_age" label="年龄" width="120"></el-table-column>
        <el-table-column
          prop="patience_link_tel"
          label="联系方式"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="main_diagnosis_name"
          label="主要诊断"
        ></el-table-column>
      </el-table>
      <el-pagination
        style="padding-right: 14px"
        @size-change="sizeChangeHandle2"
        @current-change="currentChangeHandle2"
        :current-page="pageNo2"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize2"
        :total="totalPage2"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
      <!-- <div style="display: flex;flex-direction: row-reverse;margin-top: 20px;">
					<el-pagination @size-change="sizeChangeHandle2" @current-change="currentChangeHandle2"
						:current-page="pageIndex2" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize2"
						:total="totalPage" layout="total, sizes, prev, pager, next, jumper">
					</el-pagination>
			</div>-->
      <div slot="footer" class="dialog-footer" style="padding: 0 12px;">
        <div class="tipsArea">
          没有你要找的病例？
          <span @click="gotoAdd" style="color: #009cff">点此添加</span>
        </div>
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="closeChooseMember">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import qs from "qs";
import moment from "moment";
import { idCardRegExp, idCardFunction, getAge } from "@/utils";
export default {
  components: {
    QueueTable: () => import("./components/QueueTable.vue"),
    QueueCreate: () => import("./components/QueueCreate.vue"),

    // QueueForm:()=>import("./components/QueueTable.vue")
  },
  data() {
    return {
      dialogTableVisible: false,
      dataList2: [],
      dataList3: [],
      form: {
        patienceName: "",

        mainDiagnosis: "",

        sex: "",

        startAge: "",

        endAge: "",
      },
      form2: {
        patienceName: "",

        mainDiagnosis: "",

        sex: "",

        startAge: "",

        endAge: "",
      },
      form3: {
        patienceName: "",

        mainDiagnosis: "",

        patienceSex: "",
        patienceAgeMin: "",
        patienceAgeMax: "",
      },
      typeArr: [],
      dataListSelections: [],
      dataListSelections2: [],
      dialogStatus: false,
      activeName: "second",
      queueList: [],
      pageNo: 1,
      pageSize: 10,
      totalPage: 0,
      pageNo2: 1,
      pageSize2: 10,
      totalPage2: 0,
      dataListLoading: false, //列表loading
      tabData: [], //备用列表，在调用数据时使用
      dataList: [], //列表数据
      info: {
        name: "",
        createTime: "",
        doctorName: "",
      },
      studyId: "",
      currentId: "",
    };
  },
  mounted() {
    let _this = this;
    _this.getDataList();
    _this.getPorjType();
  },
  methods: {
    getPorjType() {
      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list?type=主要诊断"),
        method: "post",
		data: {
		  stringParam1: "主要诊断",
		},
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.typeArr = data.data;
      });
    },
    handleClick(row) {
      console.log(row);
      console.log(555, row);
      row.main_diagnosis_name = row.mainDiagnosisName;
      row.patience_sex = row.patienceSex;
      row.patience_name = row.patienceName;
      row.patience_birth = row.patienceBirth;
      row.patience_age = row.patienceAge;
      row.patience_id = row.patienceId;
      row.id = row.teamsPatienceId;
      localStorage.patientInfo = JSON.stringify(row);
      this.$router.push({
        name: "case-details",
        params: row,
      });
    },
    closeChooseMember() {
      this.form2 = {
        patienceName: "",

        mainDiagnosis: "",

        sex: "",

        startAge: "",

        endAge: "",
      };
      this.dialogTableVisible = false;
    },
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getDataList2();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList2();
    },
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageNo2 = 1;
      this.getDataList3();
    },
    // 当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val;
      this.getDataList3();
    },
    selectionChangeHandle2(val) {
      this.dataListSelections2 = val;
    },
    chooseData() {},
    submit() {
      this.$confirm("确定纳入选中患者？", "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const arr = [];

          this.dataListSelections2.forEach((v) => {
            let obj = {
              studyId: this.studyId,

              patienceId: v.patience_id,

              teamsPatienceId: v.id,
              teamsId: JSON.parse(localStorage.currentTeam).id,
            };
            arr.push(obj);
          });

          const { data: res } = await this.$http({
            url: this.$http.adornUrl(`/studyPatience/add`),
            method: "post",
            headers: {
              "Content-Type": " application/json;charset=UTF-8",
            },
            data: arr,
            //data:qs.stringify(arr)
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList2();
                this.reset3();
                this.dialogTableVisible = false;
              },
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error",
              duration: 1500,
              onClose: () => {},
            });
          }
        })
        .catch(() => {});
    },
    async deleteHandle() {
      this.$confirm("确认要在此队列中，排除你所选择的病例？", "提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          this.dataListSelections.forEach((v) => {
            ids.push(v.id);
          });
          const id = ids.join(`,`);
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(`/studyPatience/update?ids=${id}`),
            method: "post",
			data:{
				stringParam1:id
			}
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          }
        })
        .catch(() => {});
    },
    gotoAdd() {
      this.$router.push("/case/create");
    },
    reset() {
      this.form = {
        patienceName: "",

        mainDiagnosis: "",

        sex: "",
        startAge: "",
        endAge: "",
      };
      this.getDataList2();
    },
    reset3() {
      this.form3 = {
        patienceName: "",

        mainDiagnosis: "",

        patienceSex: "",
        patienceAgeMin: "",
        patienceAgeMax: "",
      };
      this.getDataList3();
    },
    handleOpen(item) {
      // console.log(index);
      this.info = item;//this.dataList[index];
      this.studyId = this.info.id;
      this.currentId = this.info.id;
      this.pageNo = 1;
      this.pageSize = 10;
      this.reset();

      this.getDataList2();
    },
    async handleAdd() {
      this.dialogStatus = true;
    },
    closeDialog() {
      this.dialogStatus = false;
    },
    getDataList() {
      let that = this;
      let teamsId = "";

      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      let params = {};

      params.stringParam2 = teamsId;
      params.intParam1 = 999;
      params.intParam2 = 1;
      that.dataListLoading = true;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/cohortStudy/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            that.dataList = data.data;
            that.info = that.dataList[0];
            that.studyId = that.info.id;
            that.currentId = that.info.id;
            that.getDataList2();
            that.dataListLoading = false;
          }
        });
    },
    getDataList2() {
      let that = this;
      let teamsId = "";

      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      let params = {};

      params.stringParam1 = teamsId;
      params.intParam1 = that.pageSize;
      params.intParam2 = that.pageNo;
      params.stringParam2 = that.studyId;
      params.stringParam3 = that.form.patienceName;
      params.stringParam4 = that.form.mainDiagnosis;
      params.intParam3 = that.form.sex;
      params.intParam4 = that.form.startAge;
      params.intParam5 = that.form.endAge;

      that.dataListLoading = true;
      that
        .$httpAes({
     
          url: that.$httpAes.adornUrl("/studyPatience/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            data.data.forEach((val) => {
              if (val.patienceBirth) {
                val.patienceAge = getAge(val.patienceBirth);
              } else {
                val.patienceBirth = idCardFunction(val.patienceIdCard, 1);
                val.patienceAge = idCardFunction(val.patienceIdCard, 3);
              }
              if (
                val.patienceSexName == null ||
                val.patienceSexName.length == 0
              ) {
                val.patienceSexName = idCardFunction(val.patienceIdCard, 2);
              }
            });
            that.dataList2 = data.data;
            that.totalPage = data.totalCount;

            that.dataListLoading = false;
          }
        });
    },
    getDataList3() {
      let that = this;
      that.dialogTableVisible = true;
      let teamsId = "";

      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      let params = {};

      params.stringParam1 = teamsId;
      params.intParam1 = that.pageSize2;
      params.intParam2 = that.pageNo2;

      params.stringParam2 = that.form3.patienceName;
      params.stringParam5 = that.form3.mainDiagnosis;
      params.stringParam4 = that.form3.patienceSex;
      params.stringParam6 = that.form3.patienceAgeMin;
      params.stringParam7 = that.form3.patienceAgeMax;

      that.dataListLoading = true;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/teamsPatience/selectTeamsPatience"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            data.data.forEach((val) => {
              if (val.patience_birth) {
                val.patience_age = getAge(val.patience_birth);
              } else {
                val.patience_birth = idCardFunction(val.patience_id_card, 1);
                val.patience_age = idCardFunction(val.patience_id_card, 3);
              }
              if (
                val.patience_sex_name == null ||
                val.patience_sex_name.length == 0
              ) {
                val.patience_sex_name = idCardFunction(val.patience_id_card, 2);
              }
            });
            that.dataList3 = data.data;
            that.totalPage2 = data.totalCount;

            that.dataListLoading = false;
          }
        });
    },
    //进入设置队列
    goToSetting () {
      let routerUrl = this.$router.resolve({
        name: "setting",
        query: {studyId: this.studyId, studyName: this.info.name}
      });
      window.open(routerUrl.href, "_blank");
    }
  },
};
</script>

<style lang="scss" scoped>
.left-con {
  width: 232px;
  background: #fbfbfb;
  .con-item {
    // min-height: 70px;
    padding: 15px 37px;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-bottom: 1px solid #f0f0f0;
    &.active{
      color: #009CFF;
    }
  }
  .button {
    border-bottom: 1px solid #f0f0f0;
  }
}
.customWidth {
  width: 1200px !important;
  min-width: 1200px !important;
}
.main {
  .tipsArea {
    position: absolute;
    left: 32px;
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
  .fgline {
    width: 100%;
    margin: 24px 0;
    border-bottom: 1px solid #e9e9e9;
  }
  .el-menu-item:hover {
    background-color: #fff !important;
  }
  .el-menu-item {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .el-menu-item .active {
    border-right: 0px;
  }
  .el-menu {
    border-right: 0px;
  }
  .mainContent {
    display: flex;
    width: 100%;

    .rightContent {
      width: calc(100% - 233px);
      .el-form {
        display: flex;

        .el-form-item {
          display: flex;
          margin-right: 48px;
          white-space: nowrap;

          .el-form-item__content {
            width: 240px;
            height: 36px;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
          }
        }
      }
      .topContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 70px;
        height: 70px;
        width: 100%;
        background: #f4fbff;
        margin-bottom: 24px;
        padding-right: 24px;
        .text-con {
          display: flex;
          align-items: center;
        }
        img {
          width: 18px;
          height: 18px;
        }

        .text1 {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-left: 28px;
          margin-right: 40px;
        }

        .text2 {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-left: 6px;
        }
        .setting {
          font-size: 20px;
        }
      }
    }
  }

  .el-menu-item,
  .el-submenu__title {
    // height: 70px;
    // line-height: 70px;
    padding: 15px 37px;
    position: relative;
    -webkit-box-sizing: border-box;
    white-space: nowrap;
    list-style: none;
    display: flex;
    align-items: center;
    height: auto;
    line-height: 16px;
  }

  .zwsjArea {
    width: 100%;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 249px;
      height: 130px;
    }

    .text {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 22px;
      margin-top: 19px;
    }

    .button {
      text-align: center;
      margin-top: 47px;
      width: 102px;
      height: 36px;
      background: #0099ff;
      border-radius: 2px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
    }
  }

  .top {
    width: 100%;
    height: 70px;
    background: #f4fbff;
    display: flex;

    img {
      width: 18px;
      height: 18px;
    }

    .title {
      width: 283px;
      height: 100%;
      background-color: #fbfbfb;
      text-align: center;
      line-height: 70px;
    }

    .leftTwo,
    .rightTwo {
      height: 100%;
      width: 50%;
      overflow: hidden;
    }

    .leftTwo {
      display: flex;
      align-items: center;
      padding-left: 23px;

      .user {
        margin-right: 40px;

        img {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
      }

      .sex {
        margin-right: 47px;

        img {
          width: 19px;
          height: 19px;
        }
      }

      .age {
        margin-right: 47px;

        span {
          display: block;
          width: 50px;
          height: 22px;
          background: #faf6f0;
          border-radius: 4px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #f1800c;
          line-height: 22px;
          text-align: center;
        }
      }

      .case {
        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .rightTwo {
      position: relative;
      padding: 24px 0;

      span {
        display: block;
        position: absolute;
        right: 23px;
        width: 199px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }
  }

  .content {
    /deep/.el-tabs {
      width: 100%;

      .el-tabs__item {
        width: 232px !important;
        height: 49px;
      }
    }
  }

  .button {
    position: relative;
    left: 0px;
  }
  .divider-line {
    width: 20px;
    height: 1px;
    border: 1px solid #979797;
    display: inline-block;
    margin: 0 6px;
  }
}
</style>
